import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CallEndOutlinedIcon from '@mui/icons-material/CallEndOutlined';
import OpenInNewOffIcon from '@mui/icons-material/OpenInNewOff';

import {MHButton} from '@/components/base';
import {selectShowVideoCallWidget} from '@/redux/commonCalls/selectors';
import {showVideoCallWidget} from '@/redux/commonCalls/slice';

import {InviteInterpreter} from './InviteInterpreter';

import styles from './videoCallWidget.module.scss';

type VideoCallToolbarProps = {
    onGoToPatientPage: () => void;
    onEndCall: () => void;
    meetingId: string;
    newWindowRef: React.RefObject<Window | null>;
    onConnect: () => void;
};

export const VideoCallToolbar = ({
    onEndCall,
    onConnect,
    meetingId,
    newWindowRef,
    onGoToPatientPage,
}: VideoCallToolbarProps) => {
    const showVideoWidget = useSelector(selectShowVideoCallWidget);
    const dispatch = useDispatch();

    const showResumeWidgetButton = !showVideoWidget && meetingId;
    const handleShowWidget = useCallback(() => {
        newWindowRef.current?.close?.();
        dispatch(showVideoCallWidget());
        setTimeout(() => {
            onConnect();
        }, 500);
    }, [dispatch, onConnect, newWindowRef]);

    return (
        <div className={`${styles.headerToolbar}`}>
            <MHButton buttonType="primaryThird" onClickHandler={onGoToPatientPage} className={styles.goToPatientButton}>
                <AccountCircleOutlinedIcon />
                <span>Go to patient page</span>
            </MHButton>
            <InviteInterpreter meetingId={meetingId} />
            <MHButton buttonType="primary" onClickHandler={onEndCall} className={styles.endCallButton}>
                <CallEndOutlinedIcon />
                <span>End call</span>
            </MHButton>
            {showResumeWidgetButton && (
                <MHButton buttonType="primaryThird" onClickHandler={handleShowWidget} className={styles.resumeButton}>
                    <OpenInNewOffIcon />
                    <span>Resume</span>
                </MHButton>
            )}
        </div>
    );
};
