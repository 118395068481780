import {useCallback, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {Box} from '@mui/material';

import routes from '@/constants/routes';
import {
    selectCurrentCallMeetingId,
    selectCurrentCallPatientId,
    selectShowVideoCallWidget,
} from '@/redux/commonCalls/selectors';
import {closeVideoCallWidget, openVideoCallWidget} from '@/redux/commonCalls/slice';
import {VIDEO_PROVIDER_SETTINGS, videoProvider} from '@/services/videoService';

import {employmentSelector} from '../redux/selectors';
import {VideoCallToolbar} from './VideoCallToolbar';

const vidyoConnectorSrc = '/VidyoConnectorWidget.js';
const jquerySrc = '/jquery-3.1.1.min.js';

export const VideoCallWidget = () => {
    const dispatch = useDispatch();
    const checkWindowOpenIntervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
    const newWindowRef = useRef<Window | null>(null);
    const user = useSelector(employmentSelector);
    const meetingId = useSelector(selectCurrentCallMeetingId);
    const patientId = useSelector(selectCurrentCallPatientId);
    const showVideoCallWidget = useSelector(selectShowVideoCallWidget);
    const history = useHistory();
    const endCallRef = useRef(null);

    useEffect(() => {
        const url = new URL(window.location.href);
        const isOpen = url.searchParams.get('openVideoCallWidget');
        url.searchParams.delete('openVideoCallWidget');
        if (isOpen === '1') {
            dispatch(openVideoCallWidget());
        }
    }, [dispatch]);

    const handleConnect = useCallback(() => {
        if (!('vidyoConnector' in window)) {
            return;
        }

        vidyoConnector?.SetCameraPrivacy?.({
            privacy: false,
        });
        connectToConference(vidyoConnector);
    }, []);

    const handleDisconnect = useCallback(() => {
        if (!('vidyoConnector' in window)) {
            return;
        }

        vidyoConnector?.Disconnect?.();
        vidyoConnector?.SetCameraPrivacy?.({
            privacy: true,
        });
        vidyoConnector?.SetMicrophonePrivacy?.({
            privacy: true,
        });
    }, []);

    const handleEndCall = useCallback(() => {
        clearTimeout(endCallRef.current);
        newWindowRef.current?.close?.();
        newWindowRef.current = null;
        clearInterval(checkWindowOpenIntervalRef.current);

        endCallRef.current = setTimeout(() => {
            handleDisconnect();

            if (meetingId) {
                videoProvider.leaveCall({
                    meetingId,
                    url: VIDEO_PROVIDER_SETTINGS.VIDYO.providerSpecificBaseUrl,
                });
            }

            dispatch(closeVideoCallWidget());
        }, 1000);
    }, [dispatch, handleDisconnect, meetingId]);

    const handleGoToPatientPage = useCallback(() => {
        if (!patientId) {
            console.error('currentCallPatientId is not defined');
            return;
        }

        history.push(routes.PATIENT_SINGLE_OVERVIEW(patientId.toString()));
    }, [history, patientId]);

    useEffect(() => {
        const scriptJquery = document.createElement('script');
        const scriptVidyoConnector = document.createElement('script');

        scriptJquery.src = jquerySrc;
        scriptJquery.async = false;

        scriptVidyoConnector.src = vidyoConnectorSrc;
        scriptVidyoConnector.async = false;

        document.body.appendChild(scriptJquery);
        document.body.appendChild(scriptVidyoConnector);

        return () => {
            scriptJquery && document.body.removeChild(scriptJquery);
            scriptVidyoConnector && document.body.removeChild(scriptVidyoConnector);
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!('vidyoConnector' in window)) {
                clearInterval(interval);
                return;
            }

            if (!meetingId) {
                handleEndCall();
                clearInterval(interval);
                return;
            }

            if (newWindowRef.current?.closed && !showVideoCallWidget) {
                handleEndCall();
                clearInterval(interval);
                return;
            }

            const joinLeaveButton = document.getElementById('joinLeaveButton');

            vidyoConnector?.GetState?.().then((state: string) => {
                if (!joinLeaveButton) return;

                if (state.match(/connected/i)) {
                    joinLeaveButton.classList.add('callEnd');
                    joinLeaveButton.classList.remove('callStart');
                }

                if (state.match(/ready/i)) {
                    joinLeaveButton.classList.add('callStart');
                    joinLeaveButton.classList.remove('callEnd');
                }
            });
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [handleEndCall, meetingId, showVideoCallWidget]);

    useEffect(() => {
        if (meetingId) {
            newWindowRef.current = window.open(
                `${videoProvider.getNewWindowLink({
                    meetingId: meetingId,
                    portalUrl: VIDEO_PROVIDER_SETTINGS.VIDYO.portalUrl,
                })}&displayName=${user?.firstName} pid${
                    user?.id
                }&autoJoin=true&connectorType=browser&roomKey=${meetingId}&joinViaBrowser=true`,
                '_blank',
                'height=600, width=800, top=0, left=0, toolbar=no, menubar=no, scrollbars=no, resizable=yes, location=no, status=no, titlebar=no, alwaysRaised=yes'
            );
        }

        checkWindowOpenIntervalRef.current = setInterval(() => {
            if (!meetingId || newWindowRef.current?.closed) {
                handleEndCall();
                clearInterval(checkWindowOpenIntervalRef.current);
                newWindowRef.current = null;
            }
        }, 500);
    }, [handleEndCall, meetingId, user?.firstName, user?.id]);

    if (!meetingId) {
        return null;
    }

    return (
        <Box
            sx={{
                display: 'grid',
                gridAutoFlow: 'column',
                gap: 2,
                paddingRight: 2,
            }}
        >
            <VideoCallToolbar
                newWindowRef={newWindowRef}
                onGoToPatientPage={handleGoToPatientPage}
                onEndCall={handleEndCall}
                onConnect={handleConnect}
                meetingId={meetingId}
            />
            {/* <VideoRender
                user={user}
                newWindowRef={newWindowRef}
                currentMeetingId={meetingId}
                onDisconnect={handleDisconnect}
                onClose={handleEndCall}
            /> */}
        </Box>
    );
};
