import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PhoneIcon from '@mui/icons-material/Phone';
import {Grid} from '@mui/material';
import classnames from 'classnames';

import {MHLoader, MHRegularButton} from '@/components/base';
import {LoggingActions} from '@/constants/loggingActions';
import {hideCallNotification} from '@/redux/commonCalls/slice';
import {ActiveCall} from '@/redux/commonCalls/types';
import {StoreStateShape} from '@/redux/store/storeStateTypes';
import {loggerService} from '@/services/loggerService';

import {AdditionalInfoBlock} from '../AddtionalInfoBlock';
import {getUserName} from '../shared/utils';

import styles from '../shared/common.module.scss';
import awsCallStyles from './incomingAWSCall.module.scss';

export const IncomingAWSCall = ({callData}: {callData: ActiveCall}) => {
    const dispatch = useDispatch();

    const [hideButtons, setHideButtons] = useState(false);
    const email = useSelector((store: StoreStateShape) => store.commonUserDataReducer?.user?.email);

    const {mountCb, unmountCb} = callData;
    const patientName = getUserName(callData);

    useEffect(() => {
        mountCb && mountCb();

        return () => {
            unmountCb && unmountCb();
        };
    }, [mountCb, unmountCb]);

    const callback = () => {
        setHideButtons(false);
    };

    const acceptCallHandler = () => {
        //TODO remove when awsConnectService will be moved to TS
        // @ts-ignore
        if (window.awsConnectService) {
            setHideButtons(true);
            //TODO remove when awsConnectService will be moved to TS
            // @ts-ignore
            window.awsConnectService.acceptContact(callback);
        }

        loggerService.logEvent({
            severity: 'Info',
            email: email,
            action: LoggingActions.amazonConnectOnAcceptButton,
            message: 'Accept Call button click',
        });
    };

    const dismissCallHandler = () => {
        setHideButtons(true);
        //TODO remove when awsConnectService will be moved to TS
        // @ts-ignore
        if (window.awsConnectService) window.awsConnectService.disconnectContact(callback);
        else callback();
        dispatch(hideCallNotification(callData?.meetingId?.toString()));

        loggerService.logEvent({
            severity: 'Info',
            email: email,
            action: LoggingActions.amazonConnectOnDeclineButton,
            message: 'Decline Call button click',
        });
    };

    return (
        <div className={styles.callWrapper}>
            <div className={styles.headline}>Incoming Audio Call</div>

            <div className={classnames(styles.userInfo, awsCallStyles.awsCallUserInfo)}>
                <span className={awsCallStyles.phoneIcon}>
                    <PhoneIcon />
                    <CallReceivedIcon />
                </span>

                <div className={styles.userPicture}>
                    <PersonOutlineOutlinedIcon />
                </div>

                {!!patientName && <div className={styles.userName}>{patientName}</div>}
            </div>

            <AdditionalInfoBlock callData={callData} />

            <div>
                {hideButtons ? (
                    <MHLoader />
                ) : (
                    <Grid container justifyContent="flex-end" alignItems="center" gap="16px">
                        <MHRegularButton text="Accept Call" btnType="primary-positive" onClick={acceptCallHandler} />
                        <MHRegularButton text="Dismiss" btnType="primary-danger" onClick={dismissCallHandler} />
                    </Grid>
                )}
            </div>
        </div>
    );
};
