import {useState} from 'react';

import {MHSelect} from '@/components/base';
import {videoProvider} from '@/services/videoService';
import {generalNotify} from '@/utils/notifyMessages';

import {interpreters, testInterpreters} from './constants';

const isDev =
    window.location.hostname.match(/\.qa\.mhgi\.io/) ||
    window.location.hostname.match(/\.dev\.mhgi\.io/) ||
    window.location.hostname.match(/localhost/);

type InviteInterpreterProps = {
    meetingId: string;
};

export const InviteInterpreter = ({meetingId}: InviteInterpreterProps) => {
    const [curInterpreter, setCurInterpreter] = useState<string>('');
    const interpreterOptions = isDev ? testInterpreters : interpreters;

    const onSelect = async ({value}: {value: string}) => {
        try {
            setCurInterpreter(value);
            await videoProvider.inviteTranslator({
                meetingId,
                sipNumber: value,
            });
            generalNotify({
                title: 'Success',
                message: `Translator ${value} invited`,
                status: 'success',
            });
        } catch (error) {
            generalNotify({
                title: 'Error',
                message: `Failed to invite translator`,
                status: 'error',
            });
            console.error(error);
        }
    };

    return (
        <div
            style={{
                maxWidth: '300px',
                minWidth: '300px',
                width: '300px',
            }}
        >
            <MHSelect
                closeMenuOnSelect={true}
                label="Invite interpreter"
                onChangeHandler={onSelect}
                value={interpreterOptions.find((i) => i.value === curInterpreter)}
                options={interpreterOptions}
                showSelectAllOption
                hideSelectedOptions={false}
                isSearchable={false}
            />
        </div>
    );
};
