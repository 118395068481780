import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import {Grid} from '@mui/material';
import classnames from 'classnames';

import {VideoCallIcon} from '@/assets/icons';
import {MHButton, MHRegularButton} from '@/components/base';
import {LoggingActions} from '@/constants/loggingActions';
import routes from '@/constants/routes';
import {hideCallNotification} from '@/redux/commonCalls/slice';
import {ActiveCall} from '@/redux/commonCalls/types';
import {StoreStateShape} from '@/redux/store/storeStateTypes';
import {loggerService} from '@/services/loggerService';
import {getVideoProviderSettings, videoProvider} from '@/services/videoService';
import {browserNotify} from '@/utils/notifyMessages';

import {AdditionalInfoBlock} from '../AddtionalInfoBlock';
import {getUserName} from '../shared/utils';

import styles from '../shared/common.module.scss';
import zoomCallStyles from './incomingZoomCall.module.scss';

export const IncomingZoomCall = ({callData}: {callData: ActiveCall}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const {meetingId, patientId, mountCb, unmountCb} = callData;
    const patientName = getUserName(callData);
    const email = useSelector((store: StoreStateShape) => store.commonUserDataReducer?.user?.email);

    const videoSettings = getVideoProviderSettings(callData?.videoProvider);

    useEffect(() => {
        mountCb && mountCb();
        browserNotify('Incoming Video Call', `Incoming video call from ${patientName}`);

        return () => {
            unmountCb && unmountCb();
        };
    }, [mountCb, patientName, unmountCb]);

    const acceptHandler = () => {
        videoProvider.handleAcceptCall(callData);

        loggerService.logEvent({
            severity: 'Info',
            email: email,
            action: LoggingActions.zoomVideoOnAcceptButton,
            message: 'Accept Zoom Call button click',
        });
    };

    const dismissHandler = () => {
        videoProvider
            .declineCall({
                meetingId,
                url: videoSettings?.providerSpecificBaseUrl,
            })
            .catch((e) => {
                console.error(e);
            });
        dispatch(hideCallNotification(callData?.meetingId?.toString()));

        loggerService.logEvent({
            severity: 'Info',
            email: email,
            action: LoggingActions.zoomVideoOnDeclineButton,
            message: 'Decline Zoom Call button click',
        });
    };

    const acceptAndRedirectZoomCallHandler = () => {
        acceptHandler();
        if (patientId) history.push(routes.PATIENT_SINGLE_OVERVIEW(patientId.toString()));
    };

    return (
        <div className={styles.callWrapper}>
            <div className={styles.headline}>Incoming Video Call</div>

            <div className={classnames(styles.userInfo, zoomCallStyles.zoomUserInfo)}>
                <VideoCallIcon />
                <div className={styles.userPicture}>
                    <PersonOutlineOutlinedIcon />
                </div>

                {!!patientName && <div className={styles.userName}>{patientName}</div>}
            </div>

            <AdditionalInfoBlock
                callData={{
                    ...callData,
                    nurseFirstName: callData.assignedNurseFirstName,
                    nurseLastName: callData.assignedNurseLastName,
                }}
            />

            <Grid container justifyContent="flex-end" alignItems="center" gap="16px">
                <MHRegularButton
                    text="Accept And Go To Patient Profile"
                    btnType="primary-positive"
                    onClick={acceptAndRedirectZoomCallHandler}
                />
                <MHButton text="Accept And Stay Here" buttonType="primaryThird" onClickHandler={acceptHandler} />
                <MHRegularButton text="Dismiss" btnType="primary-danger" onClick={dismissHandler} />
            </Grid>
        </div>
    );
};
