import {MouseEventHandler, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import {Grid, Menu, MenuItem, MenuProps} from '@mui/material';
import {IMessage} from '@stomp/stompjs';
import classnames from 'classnames';

import {MHTypography} from '@/components/base';
import {videoProvider} from '@/services/videoService';
import websocketService from '@/services/webSocketService';

import {activeVideoCallsSelector, phoneCallDataSelector} from '../redux/selectors';
import {activeMeetingsCountSelector, userIdSelector} from './redux/selectors';
import {getActiveMeetingsCount, setActiveMeetingsCount} from './redux/slice';
import {Content} from './Content';

import commonStyles from '../shared/common.module.scss';
import styles from './activeMeetingsList.module.scss';

const StyledMenu = (props: MenuProps) => (
    <Menu
        classes={{paper: commonStyles.menuPaper, list: commonStyles.menuList}}
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{vertical: 'top', horizontal: 'center'}}
        {...props}
    />
);

type ActiveMeetingsListProps = {
    classes: Record<string, string>;
};

export const ActiveMeetingsList = ({classes}: ActiveMeetingsListProps) => {
    const dispatch = useDispatch();
    const {awsConnectAgentStatus, isOutboundCallActive} = useSelector(phoneCallDataSelector, shallowEqual);
    const activeVideoCallsList = useSelector(activeVideoCallsSelector, shallowEqual);
    const activeMeetingsCount = useSelector(activeMeetingsCountSelector);
    const userId = useSelector(userIdSelector);

    const [anchorCallsList, setAnchorCallsList] = useState<HTMLSpanElement>(null);
    const [anchorEl, setAnchorEl] = useState<Element>(null);

    const activeCallsExist = activeVideoCallsList.length > 0;

    useEffect(() => {
        dispatch(getActiveMeetingsCount());
    }, [dispatch]);

    useEffect(() => {
        websocketService.subscribe(`/topic/unviewed-missed-calls-count-${userId}`, (message: IMessage) => {
            const data: {unviewedMissedCallsCount: string | number} = message?.body
                ? JSON.parse(message?.body)
                : {unviewedMissedCallsCount: '0'};
            dispatch(setActiveMeetingsCount(+data?.unviewedMissedCallsCount));
        });
        return () => {
            websocketService.unsubscribe(`/topic/unviewed-missed-calls-count-${userId}`);
        };
    }, [userId, dispatch]);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
        setAnchorEl(event.currentTarget);
    };

    console.log({activeVideoCallsList});

    return (
        <>
            <Grid className={classes.gridBorder} item>
                <Grid
                    container
                    flexWrap="nowrap"
                    classes={{root: styles.notificationsIconContainer}}
                    alignItems="center"
                >
                    <div onClick={handleClick}>
                        {activeMeetingsCount ? (
                            <>
                                <NotificationsIcon
                                    className={classnames(styles.bell, {
                                        [styles.bellAnimated]: activeCallsExist,
                                        [styles.error]: !!activeMeetingsCount,
                                    })}
                                />
                                <div className={styles.counter}>{activeMeetingsCount || ''}</div>
                            </>
                        ) : (
                            <NotificationsOutlinedIcon
                                className={classnames(styles.bell, {
                                    [styles.bellAnimated]: activeCallsExist,
                                    [styles.error]: !!activeMeetingsCount,
                                })}
                            />
                        )}
                        <div
                            className={classnames(commonStyles.triangle, styles.triangle, {
                                [commonStyles.triangleActive]: Boolean(anchorEl),
                            })}
                        />
                    </div>

                    {!isOutboundCallActive && awsConnectAgentStatus === 'Available' && (
                        <span
                            onClick={(event) => {
                                setAnchorCallsList(event.currentTarget);
                            }}
                            style={{position: 'relative'}}
                        >
                            {activeCallsExist && (
                                <span className={styles.activeCallCount}>{activeVideoCallsList.length}</span>
                            )}

                            <Grid container alignItems="center">
                                <span
                                    className={styles.meetingText}
                                    style={{cursor: `${activeCallsExist ? 'pointer' : 'default'}`}}
                                >
                                    Inbound Video
                                </span>
                                {activeCallsExist && <ArrowDropDownIcon style={{marginLeft: 5}} />}
                            </Grid>
                        </span>
                    )}
                </Grid>
                <StyledMenu
                    id="active-meetings-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem classes={{root: commonStyles.menuItem}} key="urgent-alerts" onClick={handleClose}>
                        <div className={commonStyles.listTitle}>
                            <MHTypography color="white" variant="headline5">
                                Missed Patient Calls
                            </MHTypography>
                        </div>
                    </MenuItem>
                    {Boolean(anchorEl) && (
                        <div className={styles.content}>
                            <Content onClose={handleClose} />
                        </div>
                    )}
                </StyledMenu>
                {!isOutboundCallActive && awsConnectAgentStatus === 'Available' && activeCallsExist && (
                    <Menu
                        anchorEl={anchorCallsList}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorCallsList)}
                    >
                        {activeVideoCallsList.map((videoMeeting, index) => (
                            <MenuItem key={index}>
                                <span
                                    onClick={() => {
                                        if (!videoMeeting?.meetingId) {
                                            console.error('zoomMeeting.meetingId is not defined');
                                            return;
                                        }
                                        videoProvider.handleAcceptCall(videoMeeting);
                                        setAnchorCallsList(null);
                                    }}
                                >
                                    {videoMeeting?.patientFullName} calling ...
                                </span>
                            </MenuItem>
                        ))}
                    </Menu>
                )}
            </Grid>
        </>
    );
};
