import {LoggingActions} from '@/constants/loggingActions';
import {
    closeVideoCallWidget,
    openVideoCallWidget,
    showCallNotification,
    updateVideoCallsList,
    updateVideoNotifications,
} from '@/redux/commonCalls/slice';
import {ActiveCall, GetVideoCallsResponseDTO, VideoCallResponseDTO, VideoProvider} from '@/redux/commonCalls/types';
import {store} from '@/redux/store';
import audioServiceFactory from '@/services/createAudioNodes';
import {loggerService} from '@/services/loggerService';

import awsConnectService from './awsConnectApiService';
import httpApi from './httpApi_new';

type LogEventDataType = {
    severity: string;
    email: string;
    action: string;
    message: string;
};

type MeetingStatuses = 'CREATED' | 'STARTED' | 'ENDED' | 'CANCELED' | 'SUSPENDED';
type ActiveUserStatus = 'JOINED' | 'LEFT' | 'JBH_JOINED' | 'TO_BE_LEFT';

type CallData = ActiveCall;

type VideoCallStatus = {
    activeUserStatus: ActiveUserStatus;
    meetingStatus: MeetingStatuses;
};

type CommonUserDataReducer = {
    phoneCallData: {
        awsConnectAgentStatus: string;
    };
    user: {
        email: string;
    };
};

type VideoStore = {
    commonUserDataReducer: CommonUserDataReducer;
    callReducer: {
        activeVideoCallsList: CallData[];
    };
};

type AgentState = {
    name: string;
};

const MEET_ID_KEY = `meetingId`;
const VIDEO_CALL_DATA_KEY = `videoCallData`;
const ON_VIDEO_CALL = `On a Video Call`;
const OFFLINE = `Offline`;
const AVAILABLE = `Available`;
const TRUE = 'true';
const PROVIDER_AGNOSTIC_BASE_URL = '/command-center/v1/mission-control/video/meeting';

const mapVideoCallResponseToActiveCall = (videoCallResponse: VideoCallResponseDTO[]): ActiveCall[] =>
    videoCallResponse.map((videoCall) => ({
        ...videoCall.videoMeeting,
        videoProvider: videoCall.videoProvider,
    }));

const getMeetingIdFromCallData = (callData: CallData): string => {
    return callData?.meetingId ?? (callData?.joinUrl as string)?.split('/')?.slice(-1)?.[0];
};

const getZoomConnectUrlFromCallData = (callData: CallData): string =>
    (callData?.joinUrl as string) ?? callData?.startUrl ?? '';

const VIDEO_PROVIDER_SETTINGS = {
    ZOOM: {
        providerSpecificBaseUrl: '/command-center/v1/mission-control/zoom/meeting',
        isWidget: false,
        portalUrl: '',
    },
    VIDYO: {
        providerSpecificBaseUrl: '/video/vidyo/command-center/v1/video-calls/meeting',
        isWidget: true,
        portalUrl: `https://${window.VIDYO_DOMAIN}.platform.vidyo.io`,
    },
};

const URLS = {
    /// Shared
    VIDEO: (url: string) => `${url}`,
    VIDEO_ACTIVE: ({url, patientId}: {patientId: number; url: string}) => `${url}/${patientId}/active`,
    PATIENT: ({url, patientId}: {patientId: number; url: string}) => `${url}/${patientId}`,
    /// Vendor specific
    SHARED_LINK_URL: ({meetingId, portalUrl}: {meetingId: string; portalUrl: string}) =>
        `${portalUrl}/join/${meetingId}`,
    OPEN_NEW_WINDOW_LINK_URL: ({meetingId, portalUrl}: {meetingId: string; portalUrl: string}) =>
        `https://static.platform.vidyo.io/app/vidyoconnect/latest/webrtc/conf.htm?portal=${portalUrl}&roomKey=${meetingId}`,
    ACCEPT_CALL: ({url, meetingId}: {url: string; meetingId: string}) => `${url}/${meetingId}/accept`,
    DECLINE_CALL: ({url, meetingId}: {url: string; meetingId: string}) => `${url}/${meetingId}/decline`,
    LEAVE_CALL: ({url, meetingId}: {url: string; meetingId: string}) => `${url}/${meetingId}/leave`,
    STATUS: ({url, meetingId}: {url: string; meetingId: string}) => `${url}/${meetingId}/status`,
    INVITE_TRANSLATOR: ({meetingId, sipNumber, url}: {meetingId: string; sipNumber: string; url: string}) =>
        `${url}/${meetingId}/invitations/${sipNumber}`,
};

const getVideoProviderSettings = (videoProvider: VideoProvider) =>
    VIDEO_PROVIDER_SETTINGS[videoProvider] ?? VIDEO_PROVIDER_SETTINGS.ZOOM;

// eslint-disable-next-line import/group-exports
class VideoService {
    // @ts-ignore
    private history: History = null;
    private _meetingId: string | null = sessionStorage.getItem(MEET_ID_KEY) || null;
    private incomingMeetingsShortPollingId: number | null = null;
    private videoCallStatusPollingId: number | null = null;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    private agent = window?.myCPP?.agent as {getState: () => AgentState};

    public baseShortPolling = 5000;
    public patientVideoCallStatusShortPolling = 10000;

    constructor() {
        return this;
    }

    get meetingId(): string | null {
        return this._meetingId;
    }

    set meetingId(value: string | null) {
        if (value !== null) {
            sessionStorage.setItem(MEET_ID_KEY, value?.toString());
        } else {
            sessionStorage.removeItem(MEET_ID_KEY);
        }
        this._meetingId = value;
    }

    private _getAgentState = () => {
        return this.agent?.getState();
    };

    private _getAgentName = () => {
        return this._getAgentState()?.name;
    };

    private _getUserData = () => {
        return (store.getState() as VideoStore).commonUserDataReducer;
    };

    private _getAwsConnectStatus = () => {
        const userData = this._getUserData();
        return userData?.phoneCallData?.awsConnectAgentStatus;
    };

    private _openVideoCall({
        videoProvider,
        callData,
        patientId: argPatientId,
    }: {
        videoProvider: VideoProvider;
        callData: CallData;
        patientId?: number;
    }) {
        try {
            this.clearVideoCallStatusPollingId();

            const settings = getVideoProviderSettings(videoProvider);

            const meetingId = getMeetingIdFromCallData(callData);
            const patientId = callData?.patientId ?? argPatientId;

            awsConnectService.onAVideoCall(() => {
                awsConnectService.setStoreStatus(ON_VIDEO_CALL);
                this._handleAwsStatus({meetingId: meetingId, url: settings.providerSpecificBaseUrl});
            });

            if (settings.isWidget) {
                this._openVideoCallWidget({
                    meetingId,
                    patientId,
                });
                return;
            }

            const callUrl = getZoomConnectUrlFromCallData(callData);

            if (!callUrl) {
                console.error('Join url for ZOOM is not provided');
                return;
            }

            this._openNativeApp({
                meetingId,
                providerUrl: settings.providerSpecificBaseUrl,
                joinUrl: callUrl,
            });
        } catch (e) {
            console.error(e);
        }
    }

    private _openNativeAppUrl(startUrl?: string): void {
        if (!startUrl) {
            console.error('Start url is not provided');
            return;
        }

        const win = window.open(startUrl, '_blank');
        win.focus();
        setTimeout(() => {
            win.close();
        }, 15000);
    }

    public saveVideoCallDataLocalStorage = (data: ActiveCall) => {
        localStorage.setItem(VIDEO_CALL_DATA_KEY, JSON.stringify(data));
    };

    public loadVideoCallDataLocalStorage = (): ActiveCall => {
        const data = localStorage.getItem(VIDEO_CALL_DATA_KEY);
        return data ? (JSON.parse(data) as ActiveCall) : null;
    };

    public removeVideoCallDataLocalStorage = () => {
        localStorage.removeItem(VIDEO_CALL_DATA_KEY);
    };

    private _openNativeApp({
        joinUrl,
        meetingId,
        providerUrl,
    }: {
        meetingId: string;
        joinUrl: string;
        providerUrl: string;
    }): void {
        window.DISABLE_AWS_LOGIN === TRUE && this._acceptCall({meetingId, url: providerUrl});
        this._openNativeAppUrl(joinUrl);
    }

    private _openVideoCallWidget({meetingId, patientId}: {meetingId: string; patientId?: number}): void {
        store.dispatch(openVideoCallWidget({meetingId, patientId}));
    }

    private _acceptCall({meetingId, url}: {meetingId: string; url: string}) {
        httpApi
            .put({
                url: URLS.ACCEPT_CALL({
                    url,
                    meetingId,
                }),
            })
            .catch((e) => console.error(e));
    }

    private _handleIncomingMeetings = () => {
        const awsStatus: string = this._getAwsConnectStatus();
        const agentName = this._getAgentName();

        const agentUnavailable = agentName === OFFLINE || agentName === ON_VIDEO_CALL;
        const awsUnavailable =
            window.DISABLE_AWS_LOGIN === TRUE || awsStatus === OFFLINE || awsStatus === ON_VIDEO_CALL;

        const activeVideoCallsList: CallData[] = (store.getState() as VideoStore).callReducer.activeVideoCallsList;

        if (agentUnavailable && awsUnavailable) {
            return Promise.resolve();
        }

        return httpApi
            .get<GetVideoCallsResponseDTO>({
                url: URLS.VIDEO(PROVIDER_AGNOSTIC_BASE_URL),
            })
            .then((data: GetVideoCallsResponseDTO) => {
                const newActiveVideoCallsList = mapVideoCallResponseToActiveCall(data);
                const isNewCall = activeVideoCallsList?.length < newActiveVideoCallsList?.length;
                const isCallHandled = activeVideoCallsList?.length > newActiveVideoCallsList?.length;

                const curCall = newActiveVideoCallsList.slice(-1)?.[0];

                if (isNewCall && curCall?.meetingId) {
                    this._notifyOnCall(curCall);
                }

                if (isCallHandled) {
                    store.dispatch(updateVideoNotifications(newActiveVideoCallsList));
                }

                store.dispatch(updateVideoCallsList(newActiveVideoCallsList));
            });
    };

    private _cleanCallData = () => {
        const activeVideoCallsList: CallData[] = (store.getState() as VideoStore).callReducer.activeVideoCallsList;
        const listWithoutCurrentCall = activeVideoCallsList.filter(({meetingId}) => meetingId !== this.meetingId);
        store.dispatch(updateVideoCallsList(listWithoutCurrentCall));
        this.meetingId = null;
        store.dispatch(closeVideoCallWidget());
        this.clearVideoCallStatusPollingId();
        this.removeVideoCallDataLocalStorage();
    };

    private _handleAwsStatus = ({meetingId, url}: {meetingId: string; url: string}) => {
        this.meetingId = meetingId;

        this.videoCallStatusPollingId = setInterval(() => {
            httpApi
                .get<VideoCallStatus>({
                    url: URLS.STATUS({
                        url,
                        meetingId,
                    }),
                })
                .then((res: VideoCallStatus) => {
                    if (
                        res?.activeUserStatus === 'LEFT' ||
                        res?.meetingStatus === 'ENDED' ||
                        res?.meetingStatus === 'CANCELED' ||
                        res?.meetingStatus === 'SUSPENDED'
                    ) {
                        const agentName = this._getAgentName();
                        const awsConnectAgentStatus = this._getAwsConnectStatus();

                        const isOnVideoCall = agentName === ON_VIDEO_CALL || awsConnectAgentStatus === ON_VIDEO_CALL;

                        if (isOnVideoCall) {
                            awsConnectService.goAvailable(() => {
                                awsConnectService.setStoreStatus(AVAILABLE);
                            });
                        }

                        this._cleanCallData();
                    }
                })
                .catch((e) => {
                    console.error(e);
                    this.clearVideoCallStatusPollingId();
                });
        }, this.baseShortPolling) as unknown as number;
    };

    private _notifyOnCall(callerPatient: CallData): void {
        const {stopNodes, playNodes, createAudioNodes} = audioServiceFactory();
        createAudioNodes(
            () => {
                store.dispatch(
                    showCallNotification({
                        ...callerPatient,
                        mountCb: playNodes,
                        unmountCb: stopNodes,
                    })
                );
            },
            {loop: true, isPERS: ''}
        );
    }

    private async _checkPatientVideoCallStatus(patientId: number, callback: (data: {active: boolean}) => void) {
        await httpApi
            .get<boolean>({
                url: URLS.VIDEO_ACTIVE({
                    url: PROVIDER_AGNOSTIC_BASE_URL,
                    patientId,
                }),
            })
            // @ts-ignore
            .then(({active}: {active: boolean}) => callback({data: active}));
    }

    public leaveCall({meetingId, url}: {meetingId: string; url: string}) {
        return httpApi
            .post({
                url: URLS.LEAVE_CALL({
                    url,
                    meetingId,
                }),
            })
            .catch((e) => console.error(e))
            .finally(() => {
                this._cleanCallData();
                awsConnectService.goAvailable(() => {
                    awsConnectService.setStoreStatus(AVAILABLE);
                });
            });
    }

    public async declineCall({meetingId, url}: {meetingId: string; url: string}) {
        await httpApi.put({
            url: URLS.DECLINE_CALL({
                url,
                meetingId,
            }),
        });
        this.removeVideoCallDataLocalStorage();
        await this._handleIncomingMeetings();
        store.dispatch(closeVideoCallWidget());
    }

    public createVideoMeeting = (patientId: number) => {
        if (!patientId) {
            console.error('Patient id is not provided');
            return;
        }

        return httpApi
            .post<VideoCallResponseDTO>({
                url: URLS.PATIENT({
                    url: PROVIDER_AGNOSTIC_BASE_URL,
                    patientId,
                }),
            })
            .then((res: VideoCallResponseDTO) => {
                const callData = res?.videoMeeting;
                const videoProvider = res?.videoProvider;

                if (!callData || !videoProvider) {
                    console.error('Call data or video provider is not provided');
                    return;
                }

                this.saveVideoCallDataLocalStorage(callData);

                this._openVideoCall({
                    videoProvider: videoProvider,
                    callData,
                    patientId,
                });
            });
    };

    public async initiatePatientStatusShortPolling(
        patientId: number,
        callback: (data: {active: boolean}) => void
    ): Promise<number> {
        try {
            await this._checkPatientVideoCallStatus(patientId, callback);
            return setInterval(
                // eslint-disable-next-line
                () => this._checkPatientVideoCallStatus(patientId, callback),
                this.patientVideoCallStatusShortPolling
            ) as unknown as number;
        } catch (e) {
            console.error(e);
            return 0;
        }
    }

    public initiateIncomingMeetingsShortPolling() {
        this.incomingMeetingsShortPollingId = setInterval(() => {
            this._handleIncomingMeetings()?.catch((e) => e && console.error(e));
        }, this.baseShortPolling) as unknown as number;

        loggerService.logEvent<LogEventDataType>({
            severity: 'Info',
            email: this._getUserData()?.user?.email,
            action: LoggingActions.initiateShortPolling,
            message: `Zoom short polling initialized with id ${
                this.incomingMeetingsShortPollingId
            }. AWS connect status was: in store - ${this._getAwsConnectStatus()}; in awsClient - ${this._getAgentName()}`,
        });
    }

    public clearIncomingMeetingsShortPolling = () => {
        clearInterval(this.incomingMeetingsShortPollingId);

        loggerService.logEvent<LogEventDataType>({
            severity: 'Info',
            email: this._getUserData()?.user?.email,
            action: LoggingActions.stopShortPolling,
            message: `Video short polling with id ${
                this.incomingMeetingsShortPollingId
            } was stopped. AWS connect status was: in store - ${this._getAwsConnectStatus()}; in awsClient - ${this._getAgentName()}`,
        });

        this.incomingMeetingsShortPollingId = null;
    };

    public clearVideoCallStatusPollingId = () => {
        clearInterval(this.videoCallStatusPollingId);
        this.videoCallStatusPollingId = null;
    };

    public handleAcceptCall = (caller: CallData) => {
        const acceptCall = () => {
            const meetingId = getMeetingIdFromCallData(caller);
            const videoProvider = caller?.videoProvider;
            const videoProviderSettings = getVideoProviderSettings(videoProvider);
            const providerUrl = videoProviderSettings.providerSpecificBaseUrl;

            if (!meetingId || !providerUrl) {
                console.error('Meeting id or provider url is not provided');
                console.error({meetingId, providerUrl});
                return;
            }

            this.saveVideoCallDataLocalStorage(caller);

            this._openVideoCall({
                callData: caller,
                videoProvider,
            });

            this._acceptCall({meetingId, url: providerUrl});
            store.dispatch(updateVideoNotifications([]));
        };

        if (awsConnectService.checkIfConnectionExists()) {
            awsConnectService.disconnectContact(acceptCall);
            return;
        }

        acceptCall();
    };

    public removePatientStatusShortPolling = (id: number) => {
        clearInterval(id);
    };

    public setHistory = (history: History) => (this.history = history);

    public getSharedLink = ({meetingId, portalUrl}: {meetingId: string; portalUrl: string}) => {
        return URLS.SHARED_LINK_URL({meetingId, portalUrl});
    };
    public getNewWindowLink = ({meetingId, portalUrl}: {meetingId: string; portalUrl: string}) => {
        return URLS.OPEN_NEW_WINDOW_LINK_URL({meetingId, portalUrl});
    };
    public inviteTranslator = (args: {meetingId: string; sipNumber: string}): Promise<void> =>
        httpApi.post({
            url: URLS.INVITE_TRANSLATOR({
                meetingId: args.meetingId,
                sipNumber: args.sipNumber,
                url: VIDEO_PROVIDER_SETTINGS.VIDYO.providerSpecificBaseUrl,
            }),
        });
}

const videoProvider = new VideoService();

export {
    getVideoProviderSettings,
    mapVideoCallResponseToActiveCall,
    PROVIDER_AGNOSTIC_BASE_URL,
    VIDEO_PROVIDER_SETTINGS,
    videoProvider,
    VideoService,
};
